.CustomeTableString {
  table,
  th,
  td {
    border: 1px solid #ebebeb;
    border-top: none !important;
    border-left: none !important;
    border-collapse: collapse;
  }
  table {
    thead {
      tr {
        th {
          text-align: left;
          padding: 10px 16px;
          background-color: #fafafa;
          min-width: 270px;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: left;
          padding: 10px 16px;
          background-color: white;
        }
      }
    }
  }
}
